<script setup>
import { ref } from 'vue'

const route = useRoute()
const cart = useCartStore()
const auth = useAuthStore()
const type = ref(true)
const loading = ref(false)
const errorUsername = ref(null)
const errorPassword = ref(null)
const config = useRuntimeConfig()

const user = ref({
  username: '',
  password: ''
})
const router = useRouter()

const login = async () => {
  try {
    errorUsername.value = null
    errorPassword.value = null
    loading.value = true
    const x = await fetch(config.public.CMS + '/wp-json/jwt-auth/v1/token', {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(user.value)
    })

    const y = await x.json()
    if (y.code === '[jwt_auth] empty_username') {
      // The username field is empty.
      errorUsername.value = `Korisničko ime je prazno.`
    }
    if (y.code === '[jwt_auth] invalid_email') {
      // Unknown email address. Check again or try your username.
      errorUsername.value = `Nepoznata adresa e-pošte. Proverite ponovo ili pokušajte sa korisničkim imenom.`
    }
    if (y.code === '[jwt_auth] invalid_username') {
      // The username <strong>dlbrh</strong> is not registered on this site. If you are unsure of your username, try your email address instead.
      errorUsername.value = `Korisničko ime <strong>${user.value.username}</strong> nije registrovano na ovom sajtu. Ako niste sigurni u svoje korisničko ime, pokušajte sa adresom e-pošte.`
    }
    if (y.code === '[jwt_auth] empty_password') {
      // The password field is empty
      errorPassword.value = `Lozinka je prazna.`
    }
    if (y.code === '[jwt_auth] incorrect_password') {
      // The password you entered for the username dlbr is incorrect.
      errorPassword.value = `Lozinka koju ste uneli za korisničko ime <strong>${user.value.username}</strong> je netačna.`
    }
    if (y.token) {
      auth.setMe(y)
      await cart.callCart()
      if (route.query?.redirect) {
        router.push(route.query?.redirect)
      }
      else {
        router.push('/')
      }
    }
  }
  catch (err) {
    console.error(err)
  }
  finally {
    loading.value = false
  }
}
const nxt = () => {
  if (route.query?.redirect) {
    router.push(route.query?.redirect)
  }
  else {
    router.push('/')
  }
}
// const google = async () => {
//   const x = 'https://accounts.google.com/o/oauth2/auth?client_id=908530022536-kc06p4jrf9q4ro4kit2nn89c6qmtq9uh.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Fstilles.rs/login&state=eyJub25jZSI6ImZhNzUyOWYxODgiLCJyZWRpcmVjdF90byI6IlwvY21zXC93cC1hZG1pblwvb3B0aW9ucy1nZW5lcmFsLnBocD9wYWdlPWxvZ2luLXdpdGgtZ29vZ2xlJmFtcDtzZXR0aW5ncy11cGRhdGVkPXRydWUiLCJwcm92aWRlciI6Imdvb2dsZSJ9&scope=email+profile+openid&access_type=online&response_type=code'
//   await navigateTo(x, {
//     external: true
//   })
// }
</script>

<template>
  <div class="login">
    <h1>Prijavi se</h1>
    <h2>
      Nemaš nalog? <RouterLink :to="{ name: 'registracija' }">
        Registruj se
      </RouterLink>.
    </h2>
    <fieldset>
      <label>Korisničko ime ili Email adresa</label>
      <div :class="{ error: errorUsername }">
        <input v-model="user.username" type="text">
      </div>
      <div v-html="errorUsername" class="err" />
    </fieldset>
    <fieldset>
      <label>Lozinka</label>
      <div :class="{ error: errorPassword }">
        <input v-model="user.password" :type="type ? 'password' : 'text'" class="pr">
        <svg v-if="user.password !== ''" height="24" viewBox="0 -960 960 960" width="24" @click="type = !type">
          <path v-if="type"
            d="M480-320q75 0 127.5-52.5T660-500q0-75-52.5-127.5T480-680q-75 0-127.5 52.5T300-500q0 75 52.5 127.5T480-320Zm0-72q-45 0-76.5-31.5T372-500q0-45 31.5-76.5T480-608q45 0 76.5 31.5T588-500q0 45-31.5 76.5T480-392Zm0 192q-146 0-266-81.5T40-500q54-137 174-218.5T480-800q146 0 266 81.5T920-500q-54 137-174 218.5T480-200Zm0-300Zm0 220q113 0 207.5-59.5T832-500q-50-101-144.5-160.5T480-720q-113 0-207.5 59.5T128-500q50 101 144.5 160.5T480-280Z" />
          <path v-else
            d="m644-428-58-58q9-47-27-88t-93-32l-58-58q17-8 34.5-12t37.5-4q75 0 127.5 52.5T660-500q0 20-4 37.5T644-428Zm128 126-58-56q38-29 67.5-63.5T832-500q-50-101-143.5-160.5T480-720q-29 0-57 4t-55 12l-62-62q41-17 84-25.5t90-8.5q151 0 269 83.5T920-500q-23 59-60.5 109.5T772-302Zm20 246L624-222q-35 11-70.5 16.5T480-200q-151 0-269-83.5T40-500q21-53 53-98.5t73-81.5L56-792l56-56 736 736-56 56ZM222-624q-29 26-53 57t-41 67q50 101 143.5 160.5T480-280q20 0 39-2.5t39-5.5l-36-38q-11 3-21 4.5t-21 1.5q-75 0-127.5-52.5T300-500q0-11 1.5-21t4.5-21l-84-82Zm319 93Zm-151 75Z" />
        </svg>
      </div>
      <div v-html="errorPassword" class="err" />
    </fieldset>
    <div class="forgot tar pb">
      <NuxtLink to="/zaboravljena-lozinka">
        Zaboravili ste lozinku?
      </NuxtLink>
    </div>
    <button :disabled="loading" @click="login">
      PRIJAVI SE
    </button>
    <!-- <button @click="google">GOOGLE</button> -->

    <div class="or">
      <span>ili</span>
    </div>
    <h1>Brza kupovina bez naloga</h1>
    <div>
      Nije ti neophodan nalog da bi kupovao na StilLes, a nakon obavljene prve kupovine možeš lako da se
      registruješ.
    </div>

    <button class="outline" @click="nxt">
      NASTAVI BEZ NALOGA
    </button>
    <div class="support">
      Potrebna ti je podrška? <NuxtLink :to="{ name: 'kontakt' }">
        Kontaktiraj nas
      </NuxtLink>
    </div>
    <!-- <pre>
{{ user }}
    </pre> -->
  </div>
</template>

<style scoped>
.login {
  --size: 36px
}

.err {
  color: tomato;
  font-size: 12px;

  & :deep(strong) {
    font-weight: 800;
  }
}

.forgot {
  font-size: 13px;
  color: rgb()
}

.or {
  font-size: 14px;
  padding-bottom: 24px;
  color: rgb(var(--color-rgb) / .5);
}

.support {
  font-size: 13px;
}

.pb {
  padding-bottom: 16px;
}

h1 {
  font-size: 38px;
  font-weight: 800;
  padding-bottom: 8px;
  line-height: 1;
}

h2 {
  padding-bottom: 32px;
}

.tar {
  text-align: right;
}

.form {
  max-width: 256px;
  margin: 0 auto;
}

fieldset {
  margin-bottom: 16px;

  & div {
    position: relative;
  }
}

svg {
  position: absolute;
  top: 6px;
  right: 8px;
  cursor: pointer;

  &:active {
    transform: scale(.9);
  }
}

label {
  font-size: 13px;
}

button {
  height: var(--size);
  background-color: var(--brand-color);
  color: white;
  text-align: center;
  margin-top: 32px;
  margin-bottom: 32px;
  font-size: 14px;
  font-weight: 600;
  padding: 0 calc(var(--size) / 2);
  border-radius: calc(var(--size) / 2);
  box-shadow: var(--box-shadow);

  &:disabled {
    box-shadow: none;
    background-color: rgb(var(--color-rgb) / .12);
  }

  &.outline {
    background-color: transparent;
    color: var(--color);
    box-shadow: none;
    border: 1px solid;
  }
}

input {
  width: 100%;
  border: 1px solid rgb(var(--color-rgb) / .25);
  height: 36px;
  padding: 0 8px;
  border-radius: 4px;

  &.pr {
    padding-right: 32px;
  }
}

a {
  color: var(--color);
  font-weight: 600;
  border-bottom: 1px solid;
}

.error {
  color: red;

  & input {
    border-color: red;
    background-color: rgb(255 0 0 / .06);
  }
}
</style>
